import axios from "axios"  // 引入axios
import { message } from "ant-design-vue";
import { domainName } from "@/config"
import router from "@/router/index"

// 创建 instance实例
const instance = axios.create({
    // baseURL: process.env.VUE_APP_BASE_URL,
    baseURL: domainName,
    timeout: 500000000000000
});
// 请求拦截器
instance.interceptors.request.use(config => {
    let token = localStorage.getItem('token');
    if (token) {
        config.headers['accessToken'] = token;
    }
    return config; // 一定要返回config,不然在组件中会报错
}, err => {
    return Promise.reject(err)
})
// 响应拦截器
instance.interceptors.response.use(res => {
    let data = res.data;
    if (data.code == 4002 || data.code == 4003 || data.code == 4001) {
        message.error(data.msg || "网络请求错误");
        router.push(`/login`);
        // setTimeout(() => {
        //     window.location.href = window.location.protocol + '//' + window.location.host + "/login";
        // }, 500)
    } else if (data.code == 4004) {
        message.error(data.msg || "网络请求错误");
        // setTimeout(() => {
        //     window.location.href = window.location.protocol + '//' + window.location.host + "/home/noPower";
        // }, 500);
    } else if (data.code !== 200 && data.code !== 4001 && data.code != 4002 && data.code != 4003 && data.code != 4004) {
        message.error(data.msg || "网络请求错误");   // 请求错误的提示
    }
    return data    // 这个返回的组件中then方法中的数据
}, err => {
    return Promise.reject(err);
})
export default instance